import React from "react"
import SiteHeader from "./site-header"
import SiteFooter from "./site-footer"

const Layout = ({ children }) => {
  return (
    <div>
      <SiteHeader />
      <main>{children}</main>
      <SiteFooter />
    </div>
  )
}

export default Layout
